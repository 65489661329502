// src/components/Sidebar.js
import '../assets/sidebar.css';
import React from 'react';
import { FaHome, FaShoppingCart, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Sidebar = () => {
    const isMobile = window.innerWidth <= 768;

    return (
        <>
            <aside className="sidebar">
                {/* <div className="logo">Logo</div> */}
                <ul className="sidebar-menu">
                    <li>
                        <Link to="/admin">
                            <FaHome className="icon" />
                            <span>Início</span>
                        </Link>
                    </li>
                    {/* <li>
                        <Link to="/admin/compras">
                            <FaShoppingCart className="icon" />
                            <span>Compras</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/perfil">
                            <FaUser className="icon" />
                            <span>Perfil</span>
                        </Link>
                    </li> */}
                </ul>
            </aside>
        </>
    );
};

export default Sidebar;
