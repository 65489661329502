import React, { useState, useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Redirect } from 'react-router-dom';
import { scroller } from 'react-scroll';
import ScrollLink from './componentes/ScrollLink';
import VideoModal from './componentes/VideoModal';
import './assets/css/custom.css';

import CustomVideoModal from './componentes/CustomVideoModal';

const Prevenda = () => {
  const [matricula, setMatricula] = useState('');
  const [cpf, setCpf] = useState('');
  const [resultado, setResultado] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const matriculaInputRef = useRef(null);
  const cpfInputRef = useRef(null);

  const handleComprarClick = () => {
    scroller.scrollTo('home', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: -70,
    });
  };

  function abrirConversaWhatsApp() {
    // console.log('Cliquei....');
    const mensagem = encodeURIComponent("Olá, estou entrando em contato para solicitar uma proposta de compra de pacotes do *Curso de Formação de Praças* da FASPMPA");
    const numeroWhatsApp = "+55919196156672"; // Substitua pelo número de telefone do destinatário (incluindo o código do país, sem espaços ou caracteres especiais)
  
    const url = `https://api.whatsapp.com/send?phone=${numeroWhatsApp}&text=${mensagem}`;
    window.open(url, "_blank");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const inputName = e.target.name;
    const inputValue = e.target.value;

    if ((!matricula && !cpf) || (matricula && cpf)) {
      Swal.fire({
        icon: 'info',
        title: 'Busca de associado',
        text: 'Preencha apenas um dos campos (Matrícula ou CPF).',
      })
      return;
    } else {
      // Verifica se o valor inserido é um número
      if (isNaN(matricula)) {
        Swal.fire({
          icon: 'info',
          title: 'Busca de associado',
          text: 'Preencha somente com números a (Matrícula).',
        })
        // Limpar campos após a submissão
        setMatricula('');
        // Colocar o foco novamente no campo de Matrícula
        matriculaInputRef.current.focus();
        return;
      }
      if (isNaN(cpf)) {
        Swal.fire({
          icon: 'info',
          title: 'Busca de associado',
          text: 'Preencha somente com números o (CPF).',
        })
        // Limpar campos após a submissão
        setCpf('');
        // Colocar o foco novamente no campo de Matrícula
        cpfInputRef.current.focus();
        return;
      }
    }

    setIsLoading(true);

    try {
      const response = await axios.post('https://apifaspm-appeduca.vercel.app/buscaservidor', {
        matricula,
        cpf,
      });

      if (response.data.length === 0) {
        setResultado('Não encontrado');
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'desculpe, mas não encontramos informações funcionais com esses dados, favor entrar em contato com a FASPMPA para mais informações ',
        })
      } else {
        setResultado(response.data);
        localStorage.setItem('@app-id', response.data[0].id);
        localStorage.setItem('@app-cpf', response.data[0].cpf);
        localStorage.setItem('@app-matricula', response.data[0].matricula);
        localStorage.setItem('@app-nome', response.data[0].nome);
        localStorage.setItem('@app-email', response.data[0].email);
        localStorage.setItem('@app-rg', response.data[0].rg);
        localStorage.setItem('@app-celular', response.data[0].celular);
        window.location.replace(`/admin`);
      }
      // Faça algo com os dados do servidor, como exibir em uma lista, etc.
    } catch (error) {
      console.error('Erro ao buscar servidores:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {/* <div id="nt-preloader" className="preloader">
        <div className="loader03"></div>
      </div> */}
      <nav id="navigation_affix" className="scrollspy">
        <div className="container">
          <div className="navbar-brand"> <a href="https://ninetheme.com/themes/conversi/" className="img-logo"><img className="responsive-img" src="./assests/images/logo_affix.png" alt="Conversi" /></a></div>
          <ul className="nav navbar-nav">
            {/* <li style={{ cursor: 'pointer' }}><ScrollLink className="smooth-scroll" to="home" text="Inicio" /></li> */}
            <li style={{ cursor: 'pointer' }}><ScrollLink className="smooth-scroll" to="sobre" text="Sobre o Curso" /></li>
            <li style={{ cursor: 'pointer' }}><ScrollLink className="smooth-scroll" to="precos" text="Preços" /></li>
            <li className="menu-btn"><a href="#subscribe" target="_top">Quero comprar agora</a></li>
          </ul>
        </div>
      </nav>
      {/* <nav id="navigation_mobile">
        <div className="nav-menu-links">
          <ul className="mobile-nav">
            <li style={{ cursor: 'pointer' }}><ScrollLink className="smooth-scroll" to="home" text="Inicio" /></li>
            <li style={{ cursor: 'pointer' }}><ScrollLink className="smooth-scroll" to="sobre" text="Sobre o Curso" /></li>
            <li style={{ cursor: 'pointer' }}><ScrollLink className="smooth-scroll" to="precos" text="Preços" /></li>
            <li className="menu-btn"><a href="#subscribe" target="_top">Quero comprar agora</a></li>
          </ul>
        </div>
        <div className="nav-menu-button"> <button className="nav-menu-toggle"><i className="fa fa-navicon"></i></button></div>
      </nav> */}
      <div className="vc_row wpb_row vc_row-fluid">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div id="home"></div>
              <header
                id="header"
                className="header2 hero-parallax"
                data-parallax="scroll"
                data-speed="0.2"
                data-natural-width="1920"
                data-natural-height="1080"
                style={{
                  // backgroundImage: "url('https://ninetheme.com/themes/conversi/wp-content/uploads/2016/12/bg1.jpg')",
                  backgroundImage: `url('${require('./assets/images/pm-pa-back01.jpg')}')`,
                  backgroundSize: 'cover',
                }}
              >
                <div className="header-overlay">
                  <nav id="navigation" className="navbar scrollspy">
                    <div className="container">
                      {/* <div className="navbar-brand"><a href="https://ninetheme.com/themes/conversi/" className="img-logo"><img className="responsive-img" src={require('./assets/images/logo.png')} alt="Conversi" /></a></div> */}
                      <ul className="nav navbar-nav">
                        {/* <li style={{ cursor: 'pointer' }}><ScrollLink className="smooth-scroll" to="home" text="Inicio" /></li> */}
                        <li style={{ cursor: 'pointer' }}><ScrollLink className="smooth-scroll" to="sobre" text="Sobre o Curso" /></li>
                        <li style={{ cursor: 'pointer' }}><ScrollLink className="smooth-scroll" to="precos" text="Preços" /></li>
                        <li className="menu-btn link-menu"><ScrollLink className="smooth-scroll" to="precos" text="Quero comprar agora" /></li>
                      </ul>
                    </div>
                  </nav>
                  <div className="header-content">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-6 col-md-7 col-lg-6">
                          <div className="header-txt">
                            <h1>Concurso para Soldado da Polícia Militar do Estado do Pará</h1>
                            {/* <h4>Você está pronto para se tornar um policial?</h4> */}
                            {/* <VideoModal /> */}
                            {/* Botão Comprar */}
                            <CustomVideoModal videoUrl="https://www.youtube.com/embed/aqOusxxuyTA" />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-5 col-lg-offset-1">
                          <div className="header-form">
                            <div className="submit-status"></div>
                            <ul className="header-form-heading">
                              <p>O Associado do FASPM tem vantagens exclusivas na pré-venda do curso formação de praças.</p>
                            </ul>
                            <ul className="header-form-heading">
                              <li><i className="fa fa-bullhorn" aria-hidden="true"></i>
                                <p>Garanta já a vaga de seus dependentes, informe sua Matrícula ou CPF abaixo:</p>
                              </li>
                            </ul>
                            <div className="wpcf7 no-js" id="wpcf7-f38-p36-o1" lang="p-br" dir="ltr">
                              <form onSubmit={handleSubmit}>
                                <div className="affa-form-signup">
                                  <p>
                                    <input
                                      size="40"
                                      className="wpcf7-form-control wpcf7-text"
                                      id="matricula"
                                      placeholder="Matrícula"
                                      type="text"
                                      name="matricula"
                                      value={matricula}
                                      ref={matriculaInputRef} 
                                      onChange={(e) => setMatricula(e.target.value)}
                                    />
                                    <input
                                      size="40"
                                      className="wpcf7-form-control wpcf7-text"
                                      id="cpf"
                                      placeholder="CPF"
                                      type="text"
                                      name="cpf"
                                      value={cpf}
                                      ref={cpfInputRef} 
                                      onChange={(e) => setCpf(e.target.value)}
                                    />
                                    <input
                                      className="wpcf7-form-control has-spinner wpcf7-submit btn-medium"
                                      type="submit"
                                      value={isLoading ? "Buscando...": "COMPRAR AGORA"}
                                    />
                                  </p>
                                </div>
                              </form>
                            </div>
                            <p className="txt-desc">Pré-venda liberada somente para assosiados da FASPM.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            </div>
          </div>
        </div>
      </div>
      <div className="vc_row wpb_row vc_row-fluid">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div id="sobre" className="features-1 padding-top80">
                <div className="container">
                  <div className="post-heading-center">
                    <h2>Sobre o Curso</h2>
                    <p>Conheça as vantagens de fazer sua inscrição como associado.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vc_row wpb_row vc_row-fluid">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div id="features2" className="features-2">
                <div className="container">
                  <div className="row padding-bottom20">
                    <div className="col-sm-6 col-md-5 margin-bottom40">
                      <figure><img src={require("./assets/images/apple-devices.png")} alt="Image" /></figure>
                    </div>
                    <div className="col-sm-6 col-md-7 col-lg-6 col-lg-offset-1 margin-bottom20">
                      <div className="col-txt100">
                        <div className="post-heading-left post-heading-top-desc margin-bottom20">
                          <h2>Concurso para <strong>Soldado</strong> da Polícia</h2>
                        </div>
                        <p>O FASPM oferece um curso preparatório para o Concurso para Soldado da Polícia Militar do Estado do Pará. O curso foi produzido por Professores, Mestres e Doutores, alguns com experiência na carreira militar do Estado.</p>
                        <div className="list-icon">
                          <ul>
                            <li><i className="fa fa-check-square-o"></i> Vídeoaulas de alta qualidade</li>
                            <li><i className="fa fa-check-square-o"></i> Conteúdo teórico abrangente</li>
                            <li><i className="fa fa-check-square-o"></i> Atividades e exercícios para testar seu conhecimento</li>
                            <li><i className="fa fa-check-square-o"></i> 16 módulos</li>
                            <li><i className="fa fa-check-square-o"></i> +400 aulas e +170 horas de duração</li>
                            <li><i className="fa fa-check-square-o"></i> 140 atividades online</li>
                            <li><i className="fa fa-check-square-o"></i> 12 lives para correção de atividades e tirar dúvidas</li>
                            <li><i className="fa fa-check-square-o"></i> 12 aulões presenciais</li>
                            <li><i className="fa fa-check-square-o"></i> 3 simulados online</li>
                            <li><i className="fa fa-check-square-o"></i> Relatório de aprendizado</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vc_row wpb_row vc_row-fluid">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div className="features-3" id="features3">
                <div className="container">
                  <div className="row">
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap', // Set the flex-wrap property to wrap
                      }}
                    >
                      <div
                        style={{
                          flex: '1 0 200px',
                          padding: '20px',
                          boxSizing: 'border-box',
                          minWidth: '250px',
                        }}
                      >
                        <div className="col-txt120">
                          <div className="post-heading-left text-center-sm">
                            <h2>Equipe de <strong>Professores</strong></h2>
                            <p>Equipe composta por Mestres e Dutores</p>
                          </div>
                          <p>Não perca essa oportunidade! Inscreva-se agora mesmo e aumente suas chances de ser aprovado no concurso para Soldado da Polícia Militar do Estado do Pará.</p>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Profº Dr Major PM Gaudêncio</h4>
                                <p>Atualidades</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Ten Cel PM Adriano</h4>
                                <p>Código de Ética da PM</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Ten Cel PM Adriano</h4>
                                <p>Estatuto da PM</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Ten Cel PM Adriano</h4>
                                <p>Lei Orgânica Básica</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>João Vicente</h4>
                                <p>Língua Portuguesa</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Profº Georgenor</h4>
                                <p>Noções de Direito Administrativo</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Profª Ingrid</h4>
                                <p>Noções de Direito Constitucional</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Profª Débora</h4>
                                <p>Noções de Direitos Humanos</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Profº Phd Hélio</h4>
                                <p>Noções de Direito Penal</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Profº Cel PM Marcelino</h4>
                                <p>Noções de Direito Penal Militar</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Profª Mestra Luana</h4>
                                <p>Noções de Direito Processual Penal</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Profº Cel PM Marcelino</h4>
                                <p>Noções de Direito Processual Penal Militar</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Profº Cel PM Marcelino</h4>
                                <p>Noções de Legislação Penal Especial</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Profº Marivaldo</h4>
                                <p>Raciocínio Lógico</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Profº Soldado Eric</h4>
                                <p>Teste de Aptidão Física (TAF)</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="affa-col-txt">
                                <h4>Cel PM Moisés</h4>
                                <p>Inteligência Emocional</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          flex: '1 0 200px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                          boxSizing: 'border-box',
                          minWidth: '250px',
                        }}
                      >
                        <img
                          src={require("./assets/images/great-support.png")}
                          alt="Image"
                          style={{ maxWidth: '100%', maxHeight: '100%', width: '100%' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vc_row wpb_row vc_row-fluid">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div id="facts" className="bg-img vc_custom_1481038405591">
                <div className="bg-overlay wrap-container8040">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="affa-counter-txt"><i className="fa fa-smile-o"></i>
                          <h4>2560</h4>
                          <p>Atendimentos</p>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="affa-counter-txt"><i className="fa fa-user"></i>
                          <h4>16458</h4>
                          <p>Membros</p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="affa-counter-txt"><i className="fa fa-cubes"></i>
                          <h4>5</h4>
                          <p>Programas</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-img-base in" style={{
                  backgroundImage: "url('./assets/images/bg2.jpg')",
                  /* Adicione outras propriedades de estilo conforme necessário */
                }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vc_row wpb_row vc_row-fluid">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div id="precos" className="wrap-container8040">
                <div className="container">
                  <div className="post-heading-center">
                    <h2>Planos e Preços</h2>
                    <p>Desconto exclusivo até o dia 11/08 para associados da FASPM.</p>
                  </div>
                  <div className="affa-tbl-prc tbl-prc-recommended">
                    <div className="row">
                      <div className=" col-md-4 col-sm-4 col-xs-12">
                        <div className="tbl-prc-col">
                          <div className="tbl-prc-heading">
                            <h4>Plano Padrão</h4>
                            <div className="tbl-prc-price">
                              <h5>
                                {/* <span>R$</span> */}
                                750,00</h5>
                              <p>em até 10x</p>
                            </div>
                          </div>
                          {/* <ul className="tbl-prc-list">
                            <li>Amazing bonus</li>
                            <li>Another amazing bonus</li>
                            <li>Amazing bonus</li>
                            <li>Another amazing bonus</li>
                            <li>Amazing bonus</li>
                            <li>Another amazing bonus</li>
                          </ul> */}
                          <div className="tbl-prc-footer" disable><a className=" btn-small">INDISPONÍVEL</a></div>
                        </div>
                      </div>
                      <div className=" col-md-4 col-sm-4 col-xs-12">
                        <div className="tbl-prc-col tbl-prc-col-recommended">
                          <div className="tbl-prc-badge">recomendado</div>
                          <div className="tbl-prc-heading">
                            <h4>PRÉ-VENDA</h4>
                            <div className="tbl-prc-price">
                              <h5>
                                {/* <span>R$</span> */}
                                499,00</h5>
                              <p>em até 3x</p>
                            </div>
                          </div>
                          <div className="tbl-prc-footer"><span className="btn-custom btn-small" style={{ cursor: 'pointer'}} onClick={handleComprarClick}>Comprar agora</span></div>
                        </div>
                      </div>
                      <div className=" col-md-4 col-sm-4 col-xs-12">
                        <div className="tbl-prc-col">
                          <div className="tbl-prc-heading">
                            <h4>EXCLUSIVO</h4>
                            <div className="tbl-prc-price">
                              <h5>
                                {/* <span>$</span> */}
                                PACOTES</h5>
                              <p>Entre em contato</p>
                            </div>
                          </div>
                          {/* <ul className="tbl-prc-list">
                            <li>Amazing bonus</li>
                            <li>Another amazing bonus</li>
                            <li>Amazing bonus</li>
                            <li>Another amazing bonus</li>
                            <li>Amazing bonus</li>
                            <li>Another amazing bonus</li>
                          </ul> */}
                          <div className="tbl-prc-footer"><span className="btn-custom btn-small" style={{ cursor: 'pointer'}} onClick={abrirConversaWhatsApp}>Via Whatsapp</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer id="footer" className="default-footer">
        <div className="container">
          <p className="copyright-txt">FASPM. Todos os Direitos reservados</p>
          <div className="socials">
            <a className="link-facebook" href="#0" target="_blank">
              <i className="fa fa-facebook"></i>
            </a>
            <a className="link-twitter" href="#0" target="_blank">
              <i className="fa fa-twitter"></i>
            </a>
            <a className="link-google-plus" href="#0" target="_blank">
              <i className="fa fa-google-plus"></i></a>
          </div>
        </div >
      </footer >
    </div >
  );
};

export default Prevenda;
