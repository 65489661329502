// src/components/Header.js
import React from 'react';
import { FaHeadset, FaBell, FaUser } from 'react-icons/fa';

const Header = () => {

  function abrirConversaWhatsApp() {
    // console.log('Cliquei....');
    const mensagem = encodeURIComponent("Olá, estou entrando em contato para solicitar um suporte quanto à compra do pacote de pré-venda do *Curso de Formação de Praças* da FASPMPA");
    const numeroWhatsApp = "+5591988953560"; // Substitua pelo número de telefone do destinatário (incluindo o código do país, sem espaços ou caracteres especiais)
  
    const url = `https://api.whatsapp.com/send?phone=${numeroWhatsApp}&text=${mensagem}`;
    window.open(url, "_blank");
  }
  
  function logout(){
    localStorage.clear();
    window.location.replace(`/`);
  }

  return (
    <div className="header">
      <div className="item" onClick={abrirConversaWhatsApp}>
        <FaHeadset className="icon" />
        <span>Suporte</span>
      </div>
      {/* <div className="item">
        <FaBell className="icon" />
        <span>Notificações</span>
      </div> */}
      <div className="item perfil" onClick={logout}>
        <FaUser className="icon" />
        <span>Sair</span>
      </div>
    </div>
  );
};

export default Header;
