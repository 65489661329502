import React from 'react';
import Swal from 'sweetalert2';
import '../assets/css/custom.css';

const CustomVideoModal = ({ videoUrl }) => {
  const showVideoModal = () => {
    Swal.fire({
      html: `
        <div style="display: flex; justify-content: center; align-items: center;">
          <iframe width="560px" height="300px" src="${videoUrl}" frameborder="0" allowfullscreen></iframe>
        </div>
      `,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: 'swal2-popup-video', // Defina a classe personalizada para o modal
    });
  };

  return (
    <div class="header-txt-btn link-menu" onClick={showVideoModal}><a class="btn-play fancybox-media" title="Play Video"><i class="fa fa-play"></i></a></div>
  );
};

export default CustomVideoModal;