import React, { useEffect, useState } from 'react';
import Prevenda from './Prevenda';
import Admin from './admin/Admin';

const App = () => {
  const [currentURL, setCurrentURL] = useState('');

  function getURIFromURL(url) {
    try {
      const parsedURL = new URL(url);
      return parsedURL.pathname;
    } catch (error) {
      console.error('Erro ao analisar a URL:', error.message);
      return null;
    }
  }

  useEffect(() => {
    const uri = getURIFromURL(window.location.href);
    setCurrentURL(uri == '/admin' ? <Admin /> : <Prevenda />);
  }, []);

  return (
    <>
      { currentURL }
    </>
  );
};

export default App;
