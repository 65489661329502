import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import "../../admin/assets/dependenteform.css";

const DependenteForm = (props) => {
  const [nome, setNome] = useState("");
  const [idade, setIdade] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [grauparentesco, setGrauparentesco] = useState("");
  const [dependentes, setDependentes] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const [responseMessage, setResponseMessage] = useState("");
  const fetchDependentes = async () => {
    try {
      const response = await axios.get(
        `https://apigateway-appeduca.vercel.app/associados/${localStorage.getItem('@app-id')}/dependentes`
      );
      setDependentes(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Erro ao obter as vendas:", error);
    }
  };
  useEffect(() => {
    fetchDependentes();
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();



    Swal.fire({
      title: 'Você confirma a inclusção do dependente?',
      text: 'Antes de confirmar confira os dados digitados para que não haja problemas no cadastro.',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const novoDependente = { nome, idade, cpf, email, grauparentesco };
        setDependentes([...dependentes, novoDependente]);
        setNome("");
        setIdade("");
        setCpf("");
        setEmail("");
        setGrauparentesco("");
        // console.log(novoDependente);

        const cadastrarDependente = async () => {
          const data = {
            nome: nome,
            cpf: cpf,
            idade: idade,
            email: email,
            grau_parentesco_idgrau: grauparentesco,
            associados_id: localStorage.getItem('@app-id')
          };

          console.log(data);

          try {
            const response = await axios.post(
              "https://apigateway-appeduca.vercel.app/dependentes",
              data
            );
            setResponseMessage(response.data.message);
            fetchDependentes();
            Swal.fire({
              icon: 'success',
              title: 'Cadastro de dependente',
              text: 'Dependente cadastrado com sucesso.',
            })
          } catch (error) {
            setResponseMessage("Erro ao cadastrar dependente.");
            Swal.fire({
              icon: 'erro',
              title: 'Cadastro de dependente',
              text: 'Erro ao cadastrar dependente, tente novamente mais tarde.',
            })
          }
        };
        cadastrarDependente();
      } else if (result.isDenied) {
        Swal.fire('Seus dados não foram alterados', 'info')
      }
    })
  };

  const handleEdit = (index) => {
    setShowEditForm(true);
    setEditIndex(index);
    const { nome, idade, cpf, email, grauparentesco } = dependentes[index];
    setNome(nome);
    setIdade(idade);
    setCpf(cpf);
    setEmail(email);
    setGrauparentesco(grauparentesco);
  };

  const handleSaveEdit = () => {
    const novosDependentes = [...dependentes];
    novosDependentes[editIndex] = { nome, idade, cpf, email, grauparentesco };
    setDependentes(novosDependentes);
    setNome("");
    setIdade("");
    setCpf("");
    setEmail("");
    setGrauparentesco("");
    setShowEditForm(false);
    setEditIndex(null);
  };

  const handleCancelEdit = () => {
    setShowEditForm(false);
    setEditIndex(null);
    setNome("");
    setIdade("");
    setCpf("");
    setEmail("");
    setGrauparentesco("");
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group col">
            <label htmlFor="nome">Nome do Dependente</label>
            <input
              type="text"
              className="form-control"
              id="nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="form-group col">
            <label htmlFor="idade">Idade do Dependente</label>
            <input
              type="number"
              className="form-control"
              id="idade"
              value={idade}
              onChange={(e) => setIdade(e.target.value)}
            />
          </div>
          <div className="form-group col">
            <label htmlFor="cpf">CPF</label>
            <input
              type="text"
              className="form-control"
              id="cpf"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
            />
          </div>
          <div className="form-group col">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group col">
            <label htmlFor="grauparentesco">Grau de Parentesco</label>
            <select
              className="form-control"
              id="grauparentesco"
              value={grauparentesco}
              onChange={(e) => setGrauparentesco(e.target.value)}
            >
              <option value="">Selecione</option>
              <option value="1">Filho(a)</option>
              <option value="2">Cônjuge</option>
              <option value="3">Pai</option>
              <option value="4">Mãe</option>
              <option value="5">Irmão</option>
              <option value="6">Sobrinho(a)</option>
              <option value="7">Outro</option>
            </select>
          </div>
        </div>

        <button type="submit" className="btn btn-primary">
          Cadastrar
        </button>
      </form>

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Idade</th>
            <th>CPF</th>
            <th>Email</th>
            <th>Grau de Parentesco</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          {dependentes.map((dependente, index) => (
            <tr key={index}>
              <td>{dependente.nome}</td>
              <td>{dependente.idade}</td>
              <td>{dependente.cpf}</td>
              <td>{dependente.email}</td>
              <td>{dependente.grau_parentesco}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => handleEdit(index)}
                >
                  Editar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showEditForm && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCancelEdit}>
              &times;
            </span>
            <h2>Editar Dependente</h2>
            <form>
              <div className="form-row">
                <div className="form-group col">
                  <label htmlFor="editNome">Nome do Dependente</label>
                  <input
                    type="text"
                    className="form-control"
                    id="editNome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </div>
                <div className="form-group col">
                  <label htmlFor="editIdade">Idade do Dependente</label>
                  <input
                    type="number"
                    className="form-control"
                    id="editIdade"
                    value={idade}
                    onChange={(e) => setIdade(e.target.value)}
                  />
                </div>
                <div className="form-group col">
                  <label htmlFor="editCpf">CPF</label>
                  <input
                    type="text"
                    className="form-control"
                    id="editCpf"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                  />
                </div>
                <div className="form-group col">
                  <label htmlFor="editEmail">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="editEmail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group col">
                  <label htmlFor="editGrauparentesco">Grau de Parentesco</label>
                  <select
                    className="form-control"
                    id="editGrauparentesco"
                    value={grauparentesco}
                    onChange={(e) => setGrauparentesco(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    <option value="filho">Filho(a)</option>
                    <option value="conjuge">Cônjuge</option>
                    <option value="pai">Pai</option>
                    <option value="mae">Mãe</option>
                    <option value="outro">Outro</option>
                  </select>
                </div>
              </div>
              <button type="button" className="btn btn-secondary" onClick={handleCancelEdit}>
                Cancelar
              </button>
              <button type="button" className="btn btn-primary" onClick={handleSaveEdit}>
                Salvar Alterações
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default DependenteForm;
