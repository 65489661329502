import React from 'react'
import { Container, Navbar, Nav, Breadcrumb, Row, Col, Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../componentes/Header';

function Pagina1() {
  return (
    <Container fluid>
      {/* Menu e Perfil */}
      <Row className="mt-3 justify-content-end">
        <Col>
          <Header />
        </Col>
      </Row>

      {/* Breadcrumb */}
      <Row className="mt-3">
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item ><Link to="/admin">Início</Link></Breadcrumb.Item>
            <Breadcrumb.Item active>Compras</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      {/* Cards */}
      <Row className="mt-3">
        {/* Primeiro Card */}
        <Col md={8}>
          <Card>
            <Card.Title>Título do Card</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">Subtítulo</Card.Subtitle>
            <hr />
            <Card.Body>
              <Form>
                <Form.Group controlId="formNome">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control type="text" placeholder="Digite seu nome" />
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control type="email" placeholder="Digite seu e-mail" />
                </Form.Group>
                <Form.Group controlId="formCpfCnpj">
                  <Form.Label>CPF/CNPJ</Form.Label>
                  <Form.Control type="text" placeholder="Digite seu CPF ou CNPJ" />
                </Form.Group>
                <Form.Group controlId="formTelefone">
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control type="tel" placeholder="Digite seu telefone" />
                </Form.Group>
                <Form.Group controlId="formCep">
                  <Form.Label>CEP</Form.Label>
                  <Form.Control type="text" placeholder="Digite seu CEP" />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        {/* Segundo Card */}
        <Col md={4}>
          <Card>
            <Card.Title>Plano</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">PRÉ-VENDA</Card.Subtitle>
            <hr />
            <Card.Body>R$ 499,00 </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Pagina1