import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup, Form, Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactDOM from 'react-dom';
import '../assets/admin.css';
import '../assets/vendas.css';

import PaymentPage from './PaymentPage';

const Venda = () => {
    const [quantity, setQuantity] = useState(1);
    const [vltotal, setVltotal] = useState('499.00');
    const [formaPagamento, setFormaPagamento] = useState('pix');
    const [formData, setFormData] = useState({
        productImage: '',
        productValue: '',
    });

    function formatarCPF(cpf) {
        const digitsOnly = cpf.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        const match = digitsOnly.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);

        if (match) {
            return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
        }

        return cpf; // Retorna o CPF original se não puder ser formatado
    }

    const handleFormaPagamentoChange = (value) => {
        setFormaPagamento(value);
    };

    function calculaValorVenda(qtd) {
        const valor = (499.00 * qtd);
        return valor;
    }

    function formatarValorVenda(valor) {
        const valorSemVirgula = valor.toString().replace(",", ".");
        const valorFormatado = parseFloat(valorSemVirgula).toFixed(2);
        return valorFormatado;
    }

    function formatarValor(valor) {
        // Removendo possíveis vírgulas para evitar problemas com parseFloat
        const valorSemVirgula = valor.toString().replace(",", "");
        // Formatando o valor com ponto (.) como separador decimal
        const valorFormatado = parseFloat(valorSemVirgula).toFixed(2);
        return valorFormatado;
    }

    function formatarValorPix(valor) {
        // Remove todos os caracteres não numéricos do valor
        const valorLimpo = valor.replace(/\D/g, '');
        return valorLimpo;
    }

    const handleDecrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
            setVltotal(calculaValorVenda(quantity - 1));
        }
    };

    const handleIncrease = () => {
        if (quantity < 5) {
            setQuantity(quantity + 1);
            setVltotal(calculaValorVenda(quantity + 1));
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Limite de compras',
                text: 'Só é permitido a compra de até 5 unidades por associado.'
            })
        }
    };

    const handleAbrirModalPix = (url) => {
        Swal.fire({
            title: 'Modal com URL Incorporada',
            html: `<iframe width="100%" height="400px" src="${url}"></iframe>`,
            showConfirmButton: false,
            showCloseButton: true,
            customClass: {
                container: 'iframe-modal-container',
            },
        });

        // Simulando o fechamento automático após o pagamento (depois de 5 segundos)
        setTimeout(() => {
            Swal.close();
        }, 5000); // 5000 milissegundos = 5 segundos
    };

    const handleAbrirPix = () => {

        const cadastrarVenda = async (id_transacao) => {
            const data = {
                idtransacao: id_transacao,
                statusvenda: "pendente",
                formapagto: "PX",
                idproduto: "CFP23",
                qtd_dependentes: quantity,
                associados_id: localStorage.getItem('@app-id'),
            };

            try {
                const response = await axios.post(
                    "https://apigateway-appeduca.vercel.app/vendas",
                    data
                );
                console.log(response);
            } catch (error) {
                console.log("Erro ao cadastra a venda.", error);
            }
        };

        const emailVenda = async (destinatario, numvenda, produto, valor, idtransacao, qr_code_url, qr_code, parcelas, status) => {
            const data = {
                destinatario: destinatario,
                numvenda: numvenda,
                produto: produto,
                transacao: {
                    tipo: "PX",
                    valor: valor,
                    idtransacao: idtransacao,
                    qr_code_url: qr_code_url,
                    qr_code: qr_code,
                    parcelas: "1x",
                    status: status
                }
            }

            try {
                const response = await axios.post(
                    "https://apigateway-appeduca.vercel.app/enviar-email-confirmacao",
                    data
                );
                console.log(response);
            } catch (error) {
                console.log("Erro ao cadastra a venda.", error);
            }
        };

        const handleRealizarPagamento = async () => {
            const params = {
                amount: vltotal,
                salesCode: "PRE-CFP23",
                description: "Curso de Formação de Praças",
                customerName: localStorage.getItem('@app-nome'),
                customerEmail: localStorage.getItem('@app-email'),
                customerCPF: formatarCPF(localStorage.getItem('@app-cpf')),
                quantity: 1
            };

            try {
                const response = await axios.post('https://apigateway-appeduca.vercel.app/gerar-pix', params);
                console.log(response.data);
                console.log(response.data.id);
                if (response.data.status !== "failed") {
                    cadastrarVenda(response.data.id);
                    // window.open(response.data.payment.invoiceUrl, '_blank');
                    const valorFormatado = (response.data.amount / 100).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    });

                    emailVenda(localStorage.getItem('@app-email'), response.data.id, response.data.items[0].description, valorFormatado, response.data.id, response.data.charges[0].last_transaction.qr_code_url, response.data.charges[0].last_transaction.qr_code, "1", response.data.status);

                    Swal.fire({
                        title: 'Detalhes da Transação',
                        html: `
                        <div style="display: flex; flex-direction: column;">
                            <div style="align-items: center;">
                                <div style="margin-bottom: 10px;">
                                <img src="${response.data.charges[0].last_transaction.qr_code_url}" alt="QR Code" style="width: 200px; height: 200px; margin: 0 auto;"/>
                                </div>
                            </div>
                            <div style="align-items: flex-start;">
                                <strong>Pix Copia e Cola: </strong><span>${response.data.charges[0].last_transaction.qr_code}</span><br/><br/><br/>
                                <strong>ID da transação: </strong><span>${response.data.id}</span><br/>
                                <strong>Valor: </strong><span>${valorFormatado}</span><br/>
                                <strong>${response.data.items[0].description}</strong><br/>
                                <strong>Cliente: </strong><span>${response.data.customer.name}</span><br/>
                                <strong>Validade: </strong><span>${response.data.charges[0].last_transaction.expires_at}</span><br/>
                                <strong>Status: </strong><span>${response.data.status}</span>
                            </div>
                        </div>
                    `,
                        confirmButtonText: 'Fechar'
                    });
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'Transação Pix',
                        html: `Ocorreu um erro ao tentar realizar o pagamento.<br><br>${response.data.charges[0].last_transaction.gateway_response.errors[0].message}`
                    });
                }


            } catch (error) {
                let mensagemErro = 'Erro ao realizar pagamento';

                if (error.response && error.response.data && error.response.data.error && error.response.data.error.length > 0) {
                    mensagemErro = error.response.data.error[0].description;
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao realizar pagamento',
                    text: mensagemErro
                });
            }
        };

        handleRealizarPagamento();
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Evita o comportamento padrão do formulário (recarregar a página)


        if (formaPagamento == 'pix') {
            Swal.fire({
                icon: 'info',
                title: 'Pagamento via PIX',
                text: `Você confirma o pagamento de ${formatarValorVenda(vltotal)} no Pix?`,
                showCancelButton: true,
                confirmButtonText: 'Confirmar',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    handleAbrirPix();
                }
            })
        } else {
            const paymentContainer = document.createElement('div');
            ReactDOM.render(<PaymentPage total={vltotal} qtd={quantity} />, paymentContainer);

            Swal.fire({
                icon: 'info',
                text: "Pagamento via Cartão",
                html: paymentContainer,
                showConfirmButton: false,
                showCloseButton: true,
                customClass: 'modal-payment',
                didDestroy: () => {
                    ReactDOM.unmountComponentAtNode(paymentContainer);
                }
            });
        }

    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });

    };

    return (
        <Form onSubmit={handleSubmit} >
            <input type="hidden" name="productName" value="CFP2023-PRE" />
            <input type="hidden" name="productValue" value="499.000" />

            <Form.Group controlId="formProductImage" style={{ marginBottom: '20px' }}>
                {/* <img src={require('../../assets/images/org-407394_school-401374_1184f2f2c7a713db410f67e3ab73c5c0_pm-pa-foto-policiais.png')} alt="Concurso para Soldado da Polícia Militar do Estado do Pará" className="product-image" /> */}
                <img src={require('../../assets/images/capa.jpg')} alt="Concurso para Soldado da Polícia Militar do Estado do Pará" className="product-image" />
            </Form.Group>
            <Form.Group controlId="formProductValue" style={{ marginBottom: '20px' }}>
                <Form.Label>Valor</Form.Label>
                <span className='valor-input-text'>R$ {formatarValorVenda(vltotal)}</span>
            </Form.Group>

            <Form.Group controlId="formaPagamento" style={{ marginBottom: '10px' }}>
                <Form.Label>Forma de Pagamento</Form.Label>
                <ButtonGroup toggle>
                    <Button
                        variant={formaPagamento === 'pix' ? 'primary' : 'outline-primary'}
                        onClick={() => handleFormaPagamentoChange('pix')}
                        style={{ flex: 1 }}
                    >
                        Pix
                    </Button>
                    <Button
                        variant={formaPagamento === 'cartao' ? 'primary' : 'outline-primary'}
                        onClick={() => handleFormaPagamentoChange('cartao')}
                        style={{ flex: 1 }}
                    >
                        Cartão de Crédito
                    </Button>
                </ButtonGroup>
            </Form.Group>

            <Form.Group controlId="formProductQuantity" style={{ marginBottom: '20px' }}>
                <Form.Label>Quantidade</Form.Label>
                <div className="quantity-input">
                    <Button variant="secondary" onClick={handleDecrease}>-</Button>
                    <Form.Control type="number" value={quantity} className="quantity-input-text" readOnly />
                    <Button variant="secondary" onClick={handleIncrease}>+</Button>
                </div>
            </Form.Group>

            <Button variant="danger" type="submit" className='btn-compra' style={{ marginBottom: '20px' }} >
                COMPRAR AGORA
            </Button>
        </Form>
    );
};

export default Venda;
