import React from 'react';
import { Link } from 'react-scroll';

const ScrollLink = ({ to, text }) => {
  return (
    <Link to={to} smooth={true} duration={500} className="nav-link">
      {text}
    </Link>
  );
};

export default ScrollLink;
