// CreditCardForm.js
import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

const CreditCardForm = (props) => {
    const [number, setNumber] = useState('');
    const [name, setName] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvc, setCVC] = useState('');
    const [qtd_parcelas, setQtd_parcelas] = useState('');
    const [focused, setFocused] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");


    const handleInputFocus = (e) => {
        setFocused(e.target.name);
    };

    function formatarValorVenda(valor) {
        const valorSemVirgula = valor.toString().replace(",", "");
        const valorFormatado = parseFloat(valorSemVirgula).toFixed(2);
        return valorFormatado;
    }

    function formatarCPF(cpf) {
        const digitsOnly = cpf.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        const match = digitsOnly.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);

        if (match) {
            return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
        }

        return cpf; // Retorna o CPF original se não puder ser formatado
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const valorVenda = (props.vltotal);
            Swal.fire({
                icon: 'info',
                title: 'Você confirma o pagamento?',
                text: `O pagamento de R$ ${valorVenda > 0 ? formatarValorVenda(valorVenda) : formatarValorVenda(props.vltotal)} será realizado com um parelamento de ${qtd_parcelas > 0 ? qtd_parcelas : 1}x no seu cartão de crédito.`,
                showCancelButton: true,
                confirmButtonText: 'Salvar',
                denyButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    function removerBarrasESpaces(texto) {
                        const textoSemBarrasESpaces = texto.replace(/[\/\s]/g, '');
                        return textoSemBarrasESpaces;
                    }

                    function copiarDoisCaracteres(texto, posicao) {
                        if (posicao < 0 || posicao >= texto.length) {
                            return "Posição inválida.";
                        }

                        const caracteresCopiados = texto.slice(posicao, posicao + 2);
                        return caracteresCopiados;
                    }

                    function formatarValor(valor) {
                        // Removendo possíveis vírgulas para evitar problemas com parseFloat
                        const valorSemVirgula = valor.toString().replace(",", "");
                        // Formatando o valor com ponto (.) como separador decimal
                        const valorFormatado = parseFloat(valorSemVirgula).toFixed(2);
                        return valorFormatado;
                    }

                    let expira = removerBarrasESpaces(expiry);
                    let expiraMes = copiarDoisCaracteres(expira, 0);

                    let expiraano = removerBarrasESpaces(expiry);
                    let expirYear = copiarDoisCaracteres(expiraano, 2);

                    const CadastroDependente = () => {
                        const parcelas = qtd_parcelas ? qtd_parcelas : 1;
                        const valor = ( parcelas * 499.000);

                        const cadastrarVenda = async (id_transacao) => {
                            const data = {
                                idtransacao: id_transacao,
                                statusvenda: "concluida",
                                formapagto: "CC",
                                idproduto: "CFP23",
                                qtd_dependentes: props.qtd,
                                associados_id: localStorage.getItem('@app-id'),
                            };

                            try {
                                const response = await axios.post(
                                    "https://apigateway-appeduca.vercel.app/vendas",
                                    data
                                );
                                // console.log(response);
                            } catch (error) {
                                console.log("Erro ao cadastra a venda.");
                            }
                        };

                        const emailVenda = async (destinatario, numvenda, produto, valor, idtransacao, qr_code_url, qr_code, parcelas, status) => {
                            const data = {
                                destinatario: destinatario,
                                numvenda: numvenda,
                                produto: produto,
                                transacao: {
                                    tipo: "CC",
                                    valor: valor,
                                    idtransacao: idtransacao,
                                    qr_code_url: qr_code_url,
                                    qr_code: qr_code,
                                    parcelas: `${parcelas}x`,
                                    status: status
                                }
                            }
                
                            try {
                                const response = await axios.post(
                                    "https://apigateway-appeduca.vercel.app/enviar-email-confirmacao",
                                    data
                                );
                                console.log(response);
                            } catch (error) {
                                console.log("Erro ao cadastra a venda.", error);
                            }
                        };

                        const vendaParcelada = async () => {
                            const data = {
                                amount: formatarValor(valor),
                                salesCode: "PRE-CFP23",
                                description: "Curso de Formação de Praças",
                                customerName: localStorage.getItem('@app-nome'),
                                customerEmail: localStorage.getItem('@app-email'),
                                customerCPF: formatarCPF(localStorage.getItem('@app-cpf')),
                                parcelas: parcelas,
                                quantity: 1,
                                credit_card: {
                                    number: number,
                                    holderName: name,
                                    expMonth: expiraMes,
                                    expYear: expirYear,
                                    cvv: cvc
                                }
                            }

                            try {
                                const response = await axios.post(
                                    "https://apigateway-appeduca.vercel.app/receber-cartao",
                                    data
                                );
                                setResponseMessage(response);
                                if (response.data.status !== "failed") {
                                    cadastrarVenda(response.data.id);
                                    emailVenda(localStorage.getItem('@app-email'), response.data.id, response.data.items[0].description, valor, response.data.id, response.data.charges[0].last_transaction.qr_code_url, response.data.charges[0].last_transaction.qr_code, parcelas, response.data.status);
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Transação de Pagamento',
                                        text: 'Pagamento realizado com sucesso, desejamos sucesso na sua caminhada!'
                                    });
                                } else {
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Transação de Pagamento',
                                        html: `Ocorreu um erro ao tentar realizar o pagamento.<br><br>${response.data.charges[0].last_transaction.acquirer_message}`
                                    });
                                }
                            } catch (error) {
                                console.log("Erro ao cadastrar venda.", error.response.data.errors);
                                // Extrair mensagens de erro
                                const errorMessages = Object.values(error.response.data.errors).flat();
                                // Formatando mensagens de erro em uma única string
                                const formattedErrorMessages = errorMessages.join('\n');

                                Swal.fire({
                                    icon: 'info',
                                    title: 'Transação de Pagamento',
                                    html: `Ocorreu um erro ao tentar realizar o pagamento.<br><br>${formattedErrorMessages}`
                                });
                            }
                        };

                        vendaParcelada();
                    };

                    CadastroDependente();
                }
            })
        } catch (error) {
            console.error('Erro ao salvar dados:', error.message);
            Swal.fire({
                icon: 'info',
                title: 'Transação de pagamento',
                text: error.message
            });
        } finally {
            setIsLoading(false);
        }

    }

    return (
        <div>
            <Row>
                <Col md={6}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="number" style={{ marginBottom: '10px' }}>
                            <Form.Label className="text-left">Número do Cartão</Form.Label>
                            <Form.Control
                                type="text"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                placeholder="Número do Cartão"
                            />
                        </Form.Group>
                        <Form.Group controlId="name" style={{ marginBottom: '10px' }}>
                            <Form.Label className="text-left">Nome no Cartão</Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Nome no Cartão"
                            />
                        </Form.Group>

                        <Form.Group controlId="expiry" style={{ marginBottom: '10px' }}>
                            <Form.Label className="text-left">Validade</Form.Label>
                            <Form.Control
                                type="text"
                                value={expiry}
                                onChange={(e) => setExpiry(e.target.value)}
                                placeholder="MM/YY"
                            />
                        </Form.Group>
                        <Form.Group controlId="cvc" style={{ marginBottom: '10px' }}>
                            <Form.Label className="text-left">CVC</Form.Label>
                            <Form.Control
                                type="text"
                                value={cvc}
                                onChange={(e) => setCVC(e.target.value)}
                                placeholder="CVC"
                            />
                        </Form.Group>
                        {/* <Form.Group controlId="qtd_parcelas" style={{ marginBottom: '10px' }}>
                            <Form.Label className="text-left">Qt. Parcelas</Form.Label>
                            <Form.Control
                                type="number"
                                value={qtd_parcelas}
                                onChange={(e) => setQtd_parcelas(e.target.value)}
                                placeholder="3"
                            />
                        </Form.Group> */}
                        <Form.Group controlId="qtd_parcelas" style={{ marginBottom: '10px' }}>
                            <Form.Label className="text-left">Qt. Parcelas</Form.Label>
                            <Form.Control
                                as="select"  // Usamos 'as="select"' para criar um dropdown
                                value={qtd_parcelas}
                                onChange={(e) => setQtd_parcelas(e.target.value)}
                            >
                                <option value="1">1x</option>
                                <option value="2">2x</option>
                                <option value="3">3x</option>
                            </Form.Control>
                        </Form.Group>
                        <br />
                        <Button variant="primary" type="submit">
                            {isLoading ? "Aguarde..." : "PAGAR AGORA"}
                        </Button>
                    </Form>
                </Col>
                <Col md={6}>
                    <Cards
                        number={number}
                        name={name}
                        expiry={expiry}
                        cvc={cvc}
                        focused=""
                    />
                </Col>
            </Row>
        </div>
    );
};

export default CreditCardForm;
