// PaymentPage.js
import React, { useState } from 'react';
import { Tab, Tabs, Form, Button } from 'react-bootstrap';
import PixForm from './pagamentos/PixForm';
import CreditCardForm from './pagamentos/CreditCardForm';
import BoletoForm from './pagamentos/BoletoForm';

const PaymentPage = (props) => {
    return (
        <div>
            <h4>Pagamento via Cartão</h4>
            <br/>
            <CreditCardForm vltotal={props.total} qtd={props.qtd}/>
            <br/>
        </div>
    );
};

export default PaymentPage;
