import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Container, Navbar, Nav, Breadcrumb, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Header from '../componentes/Header';
import Venda from './Venda';
import DependenteForm from './DependenteForm';
import '../assets/admin.css';

function Home() {
  const [cpf, setCpf] = useState(localStorage.getItem('@app-cpf'));
  const [matricula, setMatricula] = useState("");
  const [nome, setNome] = useState('');
  const [rg, setRg] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [dadosServidor, setDadosServidor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sales, setSales] = useState([]);
  const [dependentes, setDependentes] = useState([]);

  const formatarCPF = (valorCPF) => {
    const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
    return valorCPF.replace(cpfRegex, '$1.$2.$3-$4');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Aqui você pode enviar os dados para o servidor para serem salvos
    const data = {
      cpf,
      nome,
      rg,
      matricula,
      email,
      telefone,
    };

    try {
      const response = await axios.post('https://apifaspm-appeduca.vercel.app/atualizarservidor', data);
      // console.log('Dados salvos com sucesso!', response.data);
      Swal.fire({
        icon: 'info',
        title: 'Você confirma a atualização cadastral?',
        text: 'Antes de confirmar confira os dados digitados para que não haja problemas na atualização cadastral.',
        showCancelButton: true,
        confirmButtonText: 'Salvar',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'success',
            title: 'Atualização cadastral',
            text: `Obrigado ${nome} seus dados foram atualizados com sucesso na base da FASPMPA!`
          });
        } else if (result.isDenied) {
          Swal.fire({
            icon: 'info',
            title: 'Atualização cadastral',
            text: `Seus dados não foram alterados`
          });
        }
      })
    } catch (error) {
      console.error('Erro ao salvar dados:', error.message);
      Swal.fire('Erro ao salvar dados:' + error.message, '', 'error')
    }
  };

  useEffect(() => {

    const buscarInformacoesPorCPF = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post('https://apifaspm-appeduca.vercel.app/buscaservidor', {
          matricula,
          cpf,
        });

        if (response.data.length === 0) {
          setDadosServidor('Não encontrado');
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'desculpe, mas não encontramos informações funcionais com esses dados, favor entrar em contato com a FASPMPA para mais informações ',
          })
        } else {
          setDadosServidor(response.data[0]);
          localStorage.setItem('@app-cpf', cpf);
          setNome(response.data[0].nome);
          setMatricula(response.data[0].matricula);
          setRg(response.data[0].rg);
          setEmail(response.data[0].email);
        }
      } catch (error) {
        console.error('Erro ao buscar servidores:', error.message);
      } finally {
        setIsLoading(false);
      }

    };

    if (cpf) {
      buscarInformacoesPorCPF();
    } else {
      window.location.replace(`/`);
    }

    const fetchSales = async () => {
      try {
        const response = await axios.get(
          `https://apigateway-appeduca.vercel.app/associados/${localStorage.getItem('@app-id')}/vendas`
        );
        setSales(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Erro ao obter as vendas:", error);
      }
    };

    fetchSales();

    const fetchDependentes = async () => {
      try {
        const response = await axios.get(
          `https://apigateway-appeduca.vercel.app/associados/${localStorage.getItem('@app-id')}/dependentes`
        );
        setDependentes(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Erro ao obter as vendas:", error);
      }
    };

    fetchDependentes();
  }, [cpf])


  return (
    <Container fluid>
      {/* Menu e Perfil */}
      <Row className="mt-3 justify-content-end">
        <Col>
          <Header />
        </Col>
      </Row>

      {/* Breadcrumb */}
      <Row className="mt-3">
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item active>Início</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      {/* Cards */}
      <Row className="mt-3">
        {/* Primeiro Card */}
        <Col md={8}>
          <Card>
            <Card.Title><h4>Dados do associado</h4></Card.Title>
            <Card.Subtitle className="mb-2 text-muted"><h6>Atualize seus dados cadastrais </h6></Card.Subtitle>
            <hr />
            <Card.Body>
              {dadosServidor ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formNome" style={{ marginBottom: '20px' }}>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control type="text" placeholder="Digite seu nome" className="custom-input" value={nome} onChange={(e) => setNome(e.target.value)} required />
                  </Form.Group>
                  <Form.Group controlId="formRG" style={{ marginBottom: '20px' }}>
                    <Form.Label>RG</Form.Label>
                    <Form.Control type="text" placeholder="Digite seu RG" className="custom-input" value={rg} onChange={(e) => setRg(e.target.value)} required readOnly />
                  </Form.Group>
                  <Form.Group controlId="formCpfCnpj" style={{ marginBottom: '20px' }}>
                    <Form.Label>CPF/CNPJ</Form.Label>
                    <Form.Control type="text" className="custom-input" value={formatarCPF(cpf)} required readOnly />
                  </Form.Group>
                  <Form.Group controlId="formCpfCnpj" style={{ marginBottom: '20px' }}>
                    <Form.Label>Matrícula</Form.Label>
                    <Form.Control type="text" className="custom-input" value={matricula} onChange={(e) => setMatricula(e.target.value)} required readOnly />
                  </Form.Group>
                  <Form.Group controlId="formEmail" style={{ marginBottom: '20px' }}>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control type="email" placeholder="Digite seu e-mail" className="custom-input" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </Form.Group>

                  {/* Botão Salvar */}
                  <Button variant="primary" type="submit">
                    Salvar
                  </Button>
                </Form>
              ) : (
                <p>Nenhum servidor encontrado com o CPF informado.</p>
              )}
            </Card.Body>
          </Card>
        </Col>
        {/* Segundo Card */}
        <Col md={4}>
          <Card>
            <Card.Title><h6>Plano</h6></Card.Title>
            <Card.Subtitle className="mb-2 text-muted">PRÉ-VENDA</Card.Subtitle>
            <hr />
            <Card.Body><Venda /></Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      {/* Card de Dependentes */}
      {
        sales.length > 0 ?
          sales[0].statusvenda == "concluida" ? (
            <>
              <Row className="mt-3">
                <Col md={12}>
                  <Card>
                    <Card.Title><h6>Cadstro de Dependentes</h6></Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Lista de dependentes</Card.Subtitle>
                    <hr />
                    <Card.Body>
                      <DependenteForm dataset={dependentes} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )
            : null
          : null
      }


    </Container>
  );
}

export default Home;
