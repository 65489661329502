import React from 'react';
import { BiHome, BiUser, BiCaretRight } from 'react-icons/bi';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './assets/admin.css';

import Sidebar from './componentes/Sidebar';
import AppContent from './componentes/AppContent';
import Home from './pages/Home';
import Pagina1 from './pages/Pagina1';
import Pagina2 from './pages/Pagina2';

// Componente principal da página
function Admin() {
  return (
    <div>
      <Router>
        <div className="app">
          <Sidebar />
          <main className="content">
            <Routes>
              <Route path="/admin" element={<Home />} />
              <Route path="/admin/compras" element={<Pagina1 />} />
              <Route path="/admin/perfil" element={<Pagina2 />} />
            </Routes>
          </main>
        </div>
      </Router>
    </div>
  );
}

export default Admin;
